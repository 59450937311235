/*
|
|
Category
|
|
*/
.category-page-container{
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .category{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .category-container{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    a{
      padding: .4vw 0;
      transition: opacity .4s ease;
      overflow: hidden;

      
      h2{
        font-size: 2.8vw;
        
        @include md{
          font-size: 4vw;
        }

        @include sm{
          font-size: 6vw;
        }
      }
    }

    &.is-hovering{

      a{

        &:not(.is-hovered){
          opacity: .12;
        }
      }
    }
  }
}
