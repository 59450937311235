/*
|
|
Logo
|
|
*/
.logo-container{
	position: absolute;
	left: 50%;
	margin-left: -2vw;
	margin-top: 1vw;
	z-index: $level_300;
	cursor: pointer;
	opacity: 0;
	transition: opacity .4s ease;

	a{
		transition: opacity .4s ease;

		.logo-img{
			width: 4vw;
			height: 6vw;
			transition: all 1s ease;
			background-size:cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	@include sm {
		margin-left: -4vw;

		a{

			.logo-img{
				width: 8vw;
				height: 12vw;
			}
		}
	}
}


.back-home{
	position: absolute;
	top: 0;
	left: 0;
	padding: 2vw 3vw;
	color: $white;
	mix-blend-mode: difference;
	cursor: pointer;
	z-index: $level_90;
	pointer-events: none;

	a{
		position: relative;
		overflow: hidden;
		width: 120px;
		height: 14px;

		span{
			position: absolute;
			top: 0;
			left: 0;
			transform: translateY(-100%);
			transition: transform 1s $easeSmooth .8s;
		}
	}

	@include sm {
		padding: 5vw 3vw;
	}
}
/*
|
|
Social Medias
|
|
*/
.social-container{
	position: fixed;
	top: 0;
	left: 0;
	cursor: pointer;
	mix-blend-mode: difference;
	padding: 2vw 3vw;
	z-index: $level_400;
	pointer-events: none;

	.social-ul{
		display: flex;
		align-items: center;
		overflow:hidden;

		li{
			margin-left: 1.4vw;

			a{

				svg{
					height: 12px;
					width: auto;
					fill: $white;
				}
			}
		}
	}

	@include sm {
		padding: 5vw 3vw;

		.social-ul{

			li{
				margin-left: 4vw;

				&:first-child{
					margin-left: 0vw;
				}

			}
		}
	}
}


/*
|
|
Header
|
|
*/
header{
	position: fixed;
	top: 0;
	right: 0;
	width: auto;
	height: auto;
	align-items: center;
	justify-content: space-between;
	z-index: $level_200;
	display: flex;
	mix-blend-mode: difference;

/*
|
|
Burger
|
|
*/
.burger-container{
	z-index: $level_20;
	padding: 2vw 3vw;
	position: relative;

	.close-burger{
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -20px;
		margin-top: -12px;
		background: transparent;
		z-index: $level_20;
		width: 40px;
		height: 24px;
		display: none;
		pointer-events: none;
	}

	.burger{
		width: 30px;
		cursor: pointer;
		opacity: 0;
		transition: opacity .4s ease;

		div{
			display: block;
			position: relative;
			width: 30px;
			height: 1px;
			background: $white;
			margin-bottom: 3px;
			mix-blend-mode: difference;
			transition: all .4s $easeCustom;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@include sm{
		padding: 4vw 3vw;
	}
}
}
/*
|
|
Navigation
|
|
*/
.desktop-nav{
	@include fixed();
	@include size(100%, 100%);
	color: $black;
	pointer-events: none;
	z-index: $level_10;

	.page-container{
		position: absolute;
		bottom: 0;
		left: 0;
		height: auto;
		width: 100%;
		display: flex;
		justify-content: space-between;
		z-index: $level_20;
		padding: 2vw 0vw;

		a{
			padding: 0vw 3vw;
			overflow: hidden;
		}

		@include sm{
			padding: 5vw 0vw;
		}
	}

	.category-container{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		.category-ul{
			position:relative;
			display: flex;
			justify-content: center;
			flex-direction: column;
			height: 60vh;
			width: 100%;

			.back-category{
				position: absolute;
				display: flex;
				align-items: center;
				justify-content: center;
				top: 0;
				width: 100%;
				cursor: pointer;
				z-index: $level_40;
				overflow: hidden;
			}

			.category-li{

				&:nth-child(2){

					ul{

						li{

							&:first-child{
								margin-top: 4vw;
							}
						}
					}
				}
			}

			li{
				cursor: pointer;
				padding: .4vw 0;
				overflow: hidden;

				&.category-li{
					transition: opacity .8s ease;
				}

				h2{
					margin-bottom: 0vw;
				}

				&:last-child {
					margin-bottom: 0;
				}

				.artist-ul{
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					pointer-events: none;
					cursor: default;

					li{
						opacity: 0;
						padding: .4vw 0;
						overflow: hidden;
						transition: opacity .4s ease;

						h2{
							font-size: 2.8vw;
						
							@include md{
								font-size: 4vw;
							}

							@include sm{
								font-size: 6vw;
							}
						}
					}

					&.is-hovering{

						li{

							&:not(.is-hovered){
								opacity: .12;
							}
						}
					}
				}

				// Category Active
				&.active{

					.artist-ul{

						li{
							opacity: 1;

							h2{
								margin-bottom: 0vw;
							}
						}
					}
				}
			}

			&.is-hovering{

				li{

					&.category-li{

						&:not(.is-hovered){
							opacity: .12;
						}
					}
				}
			}

			&.is-child-open{

				li{

					&.category-li{

						&:not(.active){
							pointer-events: none;
						}
					}
				}
			}
		}

		@include sm {

			.category-ul{

				li{
					margin-bottom: 4vw;

					&:last-child {
						margin-bottom: 0;
					}

					.artist-ul{

						li{
							margin-bottom: 4vw;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}

.bg-nav{
	@include fixed();
	@include size(100%, 100%);
	background: $white;
	pointer-events: none;
}

/*
|
|
Body transtions
|
|
*/
body{

	&.home{

		.logo-container{
			pointer-events: none;
		}
	}

	&.is-down, &.realisation, &.is-infos{

		.logo-container{
			pointer-events: none;

			a{
				opacity: 0;
				pointer-events: none;
				transition: opacity .4s ease;
			}
		}
	}

	&.is-menu-open{

		.scrollto-container{
			pointer-events: none;

			ul{

				li{
					transform: translateY(-100%);
				}
			}
		}

		.logo-container{

			a{
				opacity: 1;
				pointer-events: initial;
				transition: opacity .4s ease;
			}
		}

		.social-container{
			pointer-events: initial;
		}

		header{

			.close-burger{
				display: block;
				pointer-events: initial;
				cursor: pointer;
			}

			.burger{
				pointer-events: none;

				div{

					&:first-child{
						transform: translate3d(0,3px,0) rotate(-45deg);
					}

					&:nth-child(2){
						opacity: 0;
					}

					&:last-child{
						transform: translate3d(0,-6px,0) rotate(45deg);
					}
				}
			}
		}

		.desktop-nav{
			pointer-events: initial;

			.category-container{

				.category-ul{

					li{

						&.active{

							.artist-ul{
								pointer-events: auto;
							}
						}
					}
				}
			}
		}
	}

	&.about, &.contact, &.newsletter, &.artist, &.category, &.legal-notices, &.legal{

		.back-home{
			pointer-events: initial;

			a{

				span{
					transform: translateY(0%);
				}
			}
		}

		&.is-menu-open{

			.back-home{
				pointer-events: none;

				a{

					span{
						transform: translateY(-100%);
						transition: transform 1s $easeSmooth 0s;
					}
				}
			}
		}
	}

	&.artist{

		&.is-category-on, &.is-menu-open, &.is-infos{

			.back-home{
				pointer-events: none;

				a{

					span{
						transform: translateY(-100%);
						transition: transform 1s $easeSmooth 0s;
					}
				}
			}
		}
	}
}

html{

	&.is-firefox{

		.desktop-nav{
			z-index: 499;
		}

		.bg-nav{
			z-index: 498;
		}

		header{
			z-index: 500;
		}

		.social-container{
			z-index: 600;
		}

		.logo-container{
			z-index: 500;
		}

		.back-home{
			z-index: 500;
		}
	}
}
