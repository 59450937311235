/*
|
|
Media Queries
|
|
*/
$breakpoint-desktop: 1440px;
$breakpoint-laptop: 1140px;
$breakpoint-tablet: 992px;
$breakpoint-mobile: 768px;

/*
|
|
Colors
|
|
*/
$white: #fff;
$black: #000;
$greyLight: #F2F2F2;
$none: rgba(0,0,0,0);
$base: $black;

$brandOne: #F9F6EF;
$brandTwo: #FFCDBF;

/*
|
|
Fonts
|
|
*/
$title: "title", sans-serif;
$content: "content", sans-serif;


/*
|
|
Grid
|
|
*/
$col_1: 8.33333333333%;
$col_2: 16.6666666667%;
$col_3: 25.0%;
$col_4: 33.3333333333%;
$col_5: 41.6666666667%;
$col_6: 50.0%;
$col_7: 58.3333333333%;
$col_8: 66.6666666667%;
$col_9: 75.0%;
$col_10: 83.3333333333%;
$col_11: 91.6666666667%;
$col_12: 100%;

/*
|
|
Z index
|
|
*/
$level_up: 900;
$level_600: 600;
$level_500: 500;
$level_400: 400;
$level_300: 300;
$level_200: 200;
$level_100: 100;
$level_90: 90;
$level_80: 80;
$level_70: 70;
$level_60: 60;
$level_50: 50;
$level_40: 40;
$level_30: 30;
$level_20: 20;
$level_10: 10;
$level_5: 5;
$level_4: 4;
$level_3: 3;
$level_2: 2;
$level_1: 1;
$level_last: -1;

/*
|
|
Easing
|
|
*/
$easeSmooth: cubic-bezier(0.19, 1, 0.22, 1);
$easeCustom:  cubic-bezier(.86,0,.07,1);
$snap: cubic-bezier(0,1,.5,1);
$easeOutCubic: cubic-bezier(.215,.61,.355,1);
$easeInOutCubic: cubic-bezier(.645,.045,.355,1);
$easeInCirc: cubic-bezier(.6,.04,.98,.335);
$easeOutCirc: cubic-bezier(.075,.82,.165,1);
$easeInOutCirc: cubic-bezier(.785,.135,.15,.86);
$easeInExpo: cubic-bezier(.95,.05,.795,.035);
$easeOutExpo: cubic-bezier(.19,1,.22,1);
$easeInOutExpo: cubic-bezier(1,0,0,1);
$easeInQuad: cubic-bezier(.55,.085,.68,.53);
$easeOutQuad: cubic-bezier(.25,.46,.45,.94);
$easeInOutQuad: cubic-bezier(.455,.03,.515,.955);
$easeInQuart: cubic-bezier(.895,.03,.685,.22);
$easeOutQuart: cubic-bezier(.165,.84,.44,1);
$easeInOutQuart: cubic-bezier(.77,0,.175,1);
$easeInQuint: cubic-bezier(.755,.05,.855,.06);
$easeOutQuint: cubic-bezier(.23,1,.32,1);
$easeInOutQuint: cubic-bezier(.86,0,.07,1);
$easeInSine: cubic-bezier(.47,0,.745,.715);
$easeOutSine: cubic-bezier(.39,.575,.565,1);
$easeInOutSine: cubic-bezier(.445,.05,.55,.95);
$easeInBack: cubic-bezier(.6,-.28,.735,.045);
$easeOutBack: cubic-bezier(.175, .885,.32,1.275);
$easeInOutBack: cubic-bezier(.68,-.55,.265,1.55);
