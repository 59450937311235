/*
|
|
Realisation
|
|
*/

.controls{
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	margin-bottom: 8vh;
	display: flex;
	z-index: $level_40;

	p{
		cursor: pointer;
		margin-right:1.4vw;
	}

	.seek-container{
		position: absolute;
		margin: auto;
		height: 2px;
		width: 70vw;
		left: 15vw;
		top: 0;
		margin-top: 1vh;

		.progress-bar{
			position: absolute;
			height: 1px;
			width: 100%;
			background: $white;
			opacity: 0;
			transition: opacity .24s ease .3s;
		}

		input[type=range].seek-bar {
			-webkit-appearance: none;
			padding: 0;
			font: inherit;
			outline: none;
			color: $white;
			height: 1px;
			width: 100%;
			background: $white;
			box-sizing: border-box;
			opacity: .24;
			cursor: pointer;

			&::-webkit-slider-thumb {
				opacity: .01;
				-webkit-appearance: none;
				height: 20px;
				width: 16px;
				background: $white;
				cursor: pointer;
				margin-top: -1px;
			}

			&::-moz-range-thumb {
				opacity: .01;
				height: 20px;
				width: 16px;
				background: $white;
				cursor: pointer;
			}

			&::-ms-thumb {
				opacity: .01;
				height: 20px;
				width: 16px;
				background: $white;
				cursor: pointer;
			}
		}

		@include md {
			width: 55vw;
			left: 22.5vw;
		}

		@include sm {
			display: none;
		}
	}

	.full-control{
		position: absolute;
		right: 0;
		text-align: right;

		p{
			margin-right: 0;
		}
	}
	.fullscreen-c{
		cursor: pointer;
	}

	@include sm{
		margin-bottom: 4vh;
	}
}

.js-thumb {
	position: relative;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	bottom: 0;

	h1{
		margin-bottom: 0;
	}

	.horizontal-scroll{
		display: flex;
		justify-content: center;
		align-items: center;
		background: $white;
	}

	.js-shoot {
		position: relative;
		display: inline-block;
		height: 84vh;
		margin-right: 4vw;

		.img-inline{
			height: 84vh;

			img{
				height: 84vh;
				width: auto;
			}
		}

		&.first-shoot{
			height: 100vh;
			width: 100vw;
			min-width: 100vw;
			margin-top: 0vh;
			background: $black;

			.img-full{
				@include absolute();
				@include size(100%, 100%);
				background: $black;
				display: flex;

				.img-container{
					@include background();
					@include size(100%, 100%);
				}

				.img-split{
					@include background();
					@include size(50%, 100%);
				}
			}

			.artist-container{
				z-index: $level_20;

				.scroll-overlay{
					@include absolute();
					@include size(100%, 100%);
					z-index: $level_1;
					cursor: pointer;
				}

				.back-artist{
					position: absolute;
					align-self: flex-end;
					display: flex;
					flex-direction: column;
					top: 0;
					left: 0;
					margin-top: 4vh;
					z-index: $level_10;

					span{
						margin-bottom: 1vw;
						font-size: 14px;
					}

					@include sm{
						margin-top: 3vh;
					}
				}

				.realisation-content{
					position: absolute;
					bottom: 0;
					right: 0;
					margin-bottom: 8vh;
					max-width: 70vw;
					white-space: normal;
					pointer-events: none;
					z-index: $level_2;

					@include sm{
						max-width: 90vw;
						margin-bottom: 4vh;
					}
				}
			}
		}

		&.shoot-video{
			width: auto;
			cursor: pointer;

			.vimeo-wrapper{

				iframe{

					&.full-i{
						min-width: 160vh;
					}
				}
			}
		}

		&.next-thumb{
			width: 50vw;
			// margin-right: 15vw;

			.credits-container{
				position: absolute;
				height: 100%;
				width: 100%;
				white-space: normal;
				display: flex;
				justify-content: center;
				pointer-events: none;

				.description-credit{
					position: absolute;
					width: 80%;
					text-align: center;
				}

				ul{
					align-self: center;
					display: flex;
					flex-direction: column;
					width: 50%;

					&.purpose-container{
						text-align: right;
					}

					&.name-container{
						margin-left: 1.4vw;
					}

					li{

						span{
							text-transform: uppercase;

							&.name{
								font-family: $title;
							}
						}
					}
				}

			}

			.next-container{
				position: absolute;
				height: 100%;
				width: 100%;
				white-space: normal;
				display: flex;
				justify-content: center;
				align-items: center;
				text-align: center;

				.next-content{
					align-self: flex-end;
					display: flex;
					flex-direction: column;

					span{
						margin-bottom: 2vw;
						font-size: 14px;
					}

					h2{
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

html{

	body{

		&.is-playing{

			.controls{

				.seek-container{

					.progress-bar{
						opacity: 1;
					}
				}
			}
		}
	}

	&.is-device{

		body{

			&.realisation{
				overflow: hidden;
			}
		}

		.js-thumb {
			position: relative;
			overflow-x: scroll;
			overflow-y: hidden;
			display: flex;
			align-items: center;
			background: $white;

			.js-shoot {

				&.next-thumb{
					min-width: 100vw;
					padding: 0 10vw;
					display: flex;
					margin-right: 0;
					width: auto;
					flex-direction: column;
					align-items: center;

					.next-container{
						position: relative;
					}
				}
			}
		}
	}
}

