@import 'fonts';
@import 'vars';
@import 'mixins';


/*
|
|
Grid
|
|
*/
.row{
  zoom: 1;

  &:before, &:after{
    content: '';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  &:after{
    clear:both;
  }
}

.column{
  display: block;
  float: left;
  min-height: 1px;
}

.grid{
  clear: both;
  position:relative;
  margin: 0 auto;
  height: 100%;

  &.grid-small{
    width: 80vw;
  }

  &.grid-container{
    width: 94vw;
  }

  &.grid-full{
    width: 100%;
  }
}

.col-1{
  width: $col_1;
}

.offset-1{
  margin-left: $col_1;

  @include md {
    margin-left: 0px;
  }
}

.col-2{
  width: $col_2;
}

.offset-2{
  margin-left: $col_2;

  @include md {
    margin-left: 0px;
  }
}

.col-3{
  width: $col_3;
}

.offset-3{
  margin-left: $col_3;

  @include md {
    margin-left: 0px;
  }
}

.col-4{
  width: $col_4;
}

.col-5{
  width: $col_5;
}

.col-6{
  width: $col_6;
}

.col-7{
  width: $col_7;
}

.col-8{
  width: $col_8;
}

.col-9{
  width: $col_9;
}

.col-10{
  width: $col_10;
}

.col-11{
  width: $col_11;
}

.col-12{
  width: $col_12;
}

.col-lg-12{

  @include lg {
    width: $col_12;
  }
}


.col-md-8{

  @include md {
    width: $col_8;
  }
}


.col-md-12{

  @include md {
    width: $col_12;
  }
}

.col-xs-12{

  @include sm {
    width: $col_12;
  }
}

/*
|
|
Padding
|
|
*/
.pb-xs{
  padding-bottom: 2vw;
}

.pb-s{
  padding-bottom: 4vw;
}

.pb-sm{
  padding-bottom: 6vw;
}

.pb-md{
  padding-bottom: 8vw;
}

.pb-lg{
  padding-bottom: 12vw;
}

.pb-xl{
  padding-bottom: 16vw;
}

// Padding top
.pt-xs{
  padding-top: 2vw;
}

.pt-s{
  padding-top: 4vw;
}

.pt-sm{
  padding-top: 6vw;
}

.pt-md{
  padding-top: 8vw;
}

.pt-lg{
  padding-top: 12vw;
}

.pt-xl{
  padding-top: 16vw;
}

/*
|
|
margin
|
|
*/
.mb-xs{
  margin-bottom: 2vw;

  @include sm {
    margin-bottom: 6vw;
  }
}

.mb-s{
  margin-bottom: 4vw;
}

.mb-sm{
  margin-bottom: 6vw;
}

.mb-md{
  margin-bottom: 8vw;
}

.mb-lg{
  margin-bottom: 12vw;
}

.mb-xl{
  margin-bottom: 16vw;
}

// margin top
.mt-xs{
  margin-top: 2vw;
}

.mt-s{
  margin-top: 4vw;
}

.mt-sm{
  margin-top: 6vw;
}

.mt-md{
  margin-top: 8vw;
}

.mt-lg{
  margin-top: 12vw;
}

.mt-xl{
  margin-top: 16vw;
}


/*
|
|
Setup
|
|
*/
.t-upp{
  text-transform: uppercase;
}

.pe-n{
  pointer-events: none;
}

// alignments
.ta-c{
  text-align: center;
}

.ta-r{
  text-align: right;

  @include md {
    text-align: left;
  }
}

// Colors
.c-black{
  color: $black;
}

.c-white{
  color: $white;
}

// Background
.bg-black{
  background: $black;
}

.bg-white{
  background: $white;
}
