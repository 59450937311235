/*
|
|
Media Queries
|
|
*/
footer{
  padding: 3vw 2vw;
  background: $white;

  a{
    width: max-content;
  }

  .infos-footer{
    display: flex;
    justify-content: space-between;

    .info-adress{
      flex: 1;
    }

    .info-contact{
      flex: 2 1 auto;
      letter-spacing: -.5px;

      a,p{
        font-size: 100%;
      }
    }

    .info-newsletter{
      float: right;
      width: 30vw;

      p{
        font-size: 100%;
      }

      .mc4wp-form{
        margin-top: .64vw;

        .mc4wp-form-fields{
          display: flex;
          align-items: center;

          p{

            &:first-child{
              width: 100%;
            }
          }
        }

        input[type="email"]{
          font-family: $title;
          width: 100%;
          font-size: 2vw;
          line-height: 1em;
          text-transform: uppercase;
        }

        input[type="submit"]{
          background-image: url('../images/arrow.svg');
          background-size:cover;
          background-repeat: no-repeat;
          height: 5px;
          width: 17px;
          cursor: pointer;
        }
      }

      @include sm {
        float: left;
        width: 100%;

        .mc4wp-form{

          input[type="email"]{
            font-size: 2em;
          }
        }
      }
    }
  }

  .brand-wrapper{
    display: flex;
    align-items:center;

    .legal-link{

      a{
        position:relative;
        overflow: hidden;
        width: 16vw;
        height: 16px;

        h5{
          position: absolute;
          transition: transform 1s $easeSmooth;

          &:nth-child(2){
            transform: translateY(100%);
          }
        }

        &:hover{

          h5{

           &:first-child{
            transform: translateY(-100%);
          }

          &:nth-child(2){
            transform: translateY(0%);
          }
        }
      }

      @include md {
        width: 30vw;
      }

      @include sm {
        width: 100%;
      }
    }
  }
}

@include md {

  .infos-footer{
    flex-direction: column;
  }

  .info-adress{
    margin-top:4vw;
  }

  .info-contact{
    margin-top:4vw;
  }

  .info-newsletter{
    margin-top:4vw;
  }
}

@include sm {
  padding: 6vw 2vw;

  .info-adress{
    margin-top:6vw;
  }

  .info-contact{
    margin-top:6vw;
  }

  .info-newsletter{
    margin-top:6vw;
  }
}
}

.cursor-container{
  position: absolute;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  padding: 2vw;
  z-index: $level_600;
  mix-blend-mode: difference;
  -webkit-backface-visibility:hidden;
  transition: margin .4s ease;

  &:before{
    content: '';
    position: absolute;
    height: 80px;
    width: 80px;
    top: 0;
    left: 0;
    border: 1px solid $white;
    border-radius: 50%;
    opacity: .4;
    transform: scale(.4);
    pointer-events: none;
    transition: opacity .4s ease .4s, transform .8s $easeSmooth;
  }

  .cursor-project{
    position: absolute;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    span{
      opacity: 0;
      transition: opacity .4s ease, transform .8s $easeSmooth;
    }
  }

  .cursor-back{
    position: absolute;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    span{
      opacity: 0;
      transition: opacity .4s ease, transform .8s $easeSmooth;
    }
  }

  .cursor-close{
    position: absolute;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    span{
      opacity: 0;
      transition: opacity .4s ease, transform .8s $easeSmooth;
    }
  }

  .cursor-play{
    position: absolute;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    span{
      opacity: 0;
      transition: opacity .4s ease, transform .8s $easeSmooth;
    }
  }

  .cursor-pause{
    position: absolute;
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    span{
      opacity: 0;
      transition: opacity .4s ease, transform .8s $easeSmooth;
    }
  }
}

body{

  &.is-circle-link{

    .cursor-container{
      margin: -40px 0 0 -40px;

      &:before{
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &.is-close-link{

    .cursor-container{
      margin: -40px 0 0 -40px;


      &:before{
        opacity: 1;
        transform: scale(1);
      }

      .cursor-close{

        span{
          opacity: 1;
        }
      }
    }
  }

  &.is-play-link{

    .cursor-container{
      margin: -40px 0 0 -40px;


      &:before{
        opacity: 1;
        transform: scale(1);
      }

      .cursor-play{

        span{
          opacity: 1;
        }
      }
    }
  }

  &.is-pause-link{

    .cursor-container{
      margin: -40px 0 0 -40px;


      &:before{
        opacity: 1;
        transform: scale(1);
      }

      .cursor-pause{

        span{
          opacity: 1;
        }
      }
    }
  }

  &.is-project-link{

    .cursor-container{
      margin: -40px 0 0 -40px;

      &:before{
        opacity: 1;
        transform: scale(1);
      }

      .cursor-project{

        span{
          opacity: 1;
        }
      }
    }
  }

  &.is-back-link{

    .cursor-container{
      margin: -40px 0 0 -40px;

      &:before{
        opacity: 1;
        transform: scale(1);
      }

      .cursor-back{

        span{
          opacity: 1;
        }
      }
    }
  }
}

html{

  &.is-device{

    .cursor-container{
      display: none;
    }
  }
}
