/*
|
|
About
|
|
*/
.about-hero{
	position: relative;
	height: auto;
	padding: 20vw 0 12vw 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	background: $white;

	.title-container{
		overflow: hidden;
		text-align: center;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		h2{
			line-height: .84em;
			margin-bottom: 2vw;
			overflow: hidden;

			div{
				overflow:hidden;
			}
		}
	}

	@include sm{
		padding: 30vw 0 12vw 0;
	}
}

.members-container{
	background: $black;
	transform: translate3d(0vw, 10vw, 0);

	.grid{
		transform: translate3d(0vw, -10vw, 0);
	}

	.member{
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 10vw;

		&:nth-child(even){
			flex-direction: row-reverse;

			.member-content{
				transform: translate3d(-6vw,10vw,0);
				left: 0;
			}
		}

		img{
			width: 40vw;
		}

		.member-content{
			position: absolute;
			padding: 6vw 6vw;
			background: $black;
			color: $white;
			transform: translate3d(24vw, 10vw, 0);
			width: 50vw;
			bottom: 0;

			h2{
				margin-bottom: 3vw;
			}

			p{
				font-size: .89em;
				width: 80%;
				margin-bottom: 2vw;
			}

			h4{
				margin-bottom:1.4vw;
			}

			.link-contact{

				h4{
					font-family: $content;
					text-transform: none;
				}
			}
		}
	}

	@include md{
		transform: translate3d(0vw, 10vw, 0);

		.member{
			flex-direction: column;

			&:nth-child(even){
				flex-direction: column;

				.member-content{
					transform: translate3d(0vw, 0vw, 0);
				}
			}

			img{
				width: 80vw;
				margin-bottom: 4vw;
			}

			.member-content{
				position: relative;
				padding: 6vw 6vw;
				transform: translate3d(0vw, 0vw, 0);
				width: 80vw;
			}
		}
	}

		@include sm{

		.member{

			.member-content{
				padding: 6vw 0vw;

				p{
					width: 100%;
				}
			}
		}
	}
}

.about-contact-section{
	padding: 12vw 0;
	text-align: center;

	.contact-title{
		font-family: $content;
		text-transform: uppercase;
		font-size: 2.64vw;

		div{
			overflow: hidden;
		}

		span{
			font-size:1em;
			transition: opacity .4s ease;
		}

		a{
			font-family: $title;
			display: inline-block;
			transition: opacity .4s ease;
		}

		&.is-hovered{

			span{
				opacity: .24;
			}

			a{
				opacity: .24;

				&.is-hovering{
					opacity: 1;
				}
			}
		}
	}

	@include md{

		.contact-title{
			font-size: 4.6vw;

			span, a{
				font-size: 4.6vw;
			}

			br{
				display: none;
			}
		}
	}

	@include sm{

		.contact-title{
			font-size: 1.16em;
			line-height: 1.16em;

			span, a{
				font-size: 1.16em;
			}

			br{
				display: block;
			}
		}
	}
}
