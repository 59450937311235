@import '../includes';

main{
  background: $white;
  z-index: $level_400;
}

.js-view{
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin: auto;
  will-change: transform;
}

.js-title, .js-content, .js-link{
  overflow: hidden;
}

html{

  &.is-device, &.is-firefox{

    .js-view{
      position: relative;
    }
  }
}
