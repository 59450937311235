/*
|
|
Contact
|
|
*/

.contact-section{
	position: relative;
	height: 100vh;
	width: 100%;

	.contact-container{
		position: absolute;
		width: auto;
		bottom: 0;
		right: 0;
		margin-bottom: 3vw;
		width: 100%;
		text-align: center;

		.contact-title{
			font-family: $content;
			text-transform: uppercase;
			line-height: .9em;
			font-size: 2.64vw;

			div{
				overflow: hidden;
			}

			span{
				font-size:1em;
				transition: opacity .4s ease;
			}

			a{
				font-family: $title;
				display: inline-block;
				transition: opacity .4s ease;
			}

			&.is-hovered{

				span{
					opacity: .24;
				}

				a{
					opacity: .24;

					&.is-hovering{
						opacity: 1;
					}
				}
			}

			@include md{
				font-size: 4.6vw;

				span, a{
					font-size: 4.6vw;
				}

				br{
					display: none;
				}
			}

			@include sm{
				font-size: 1.16em;
				line-height: 1.16em;

				span, a{
					font-size: 1.16em;
				}

				br{
					display: block;
				}
			}
		}

		@include sm{
			margin-bottom: 6vw;
		}
	}
}

/*
|
|
newsletter
|
|
*/

.newsletter-section{
	position: relative;
	height: 100vh;
	width: 100%;

	.newsletter-container{
		position: absolute;
		width: 60vw;
		bottom: 0;
		right: 0;
		text-align: right;
		margin-bottom: 8vh;
		margin-right: 3vw;

		.newsletter-title{
			text-transform: uppercase;
			font-size: 2vw;

			div{
				overflow: hidden;
			}

			a{
				font-family: $title;
				display: inline-block;
			}
		}
	}
}
