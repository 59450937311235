html{
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  // -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  // -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html, body{
  -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a,
body,
button,
div,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
img,
input,
label,
li,
nav,
p,
select,
span,
table,
textarea,
ul{
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
}

li,
ul{
  list-style: none;
}

button,
input,
select,
textarea{
  display: block;
  box-sizing: border-box;
  border-radius: 0;
  outline: none;
  border: none;
  background: none;
  color: inherit;
}

button:focus,
input:focus,
textarea:focus{
  outline: none;
}

button:hover,
input[type=submit]:hover{
  cursor: pointer;
}

input::-ms-clear{
  display: none;
}

textarea{
  overflow: auto;
}

a{
  color: inherit;
  text-decoration: none;
}

a,
img,
picture,
svg,
video{
  display: block;
}

img,
video{
  width: 100%;
  height: auto;
}

iframe,
svg{
  width: 100%;
  height: 100%;
}

iframe{
  position: absolute;
  top: 0;
  left: 0;
}

/*
|
|
Fonts size
|
|
*/

h1{
  font-family: $title;
  font-size: 3.68vw;
  line-height: 1em;
  font-weight: 500;
  margin-bottom: 1.2vw;
  overflow: hidden;
  text-transform: uppercase;
}

h2{
  font-family: $title;
  font-size: 3.68vw;
  line-height: 1em;
  font-weight: 500;
  margin-bottom: .6vw;
  text-transform: uppercase;
}

h3{
  font-family: $title;
  font-size: 2vw;
  line-height: 1em;
  font-weight: 500;
  text-transform: uppercase;
}

h4{
  font-family: $title;
  font-size: 1.2em;
  line-height: 1em;
  font-weight: 500;
  text-transform: uppercase;
}

h5{
  font-family: $title;
  font-size: 1em;
  line-height: 1em;
  font-weight: 500;
  text-transform: uppercase;
}

p{
  font-size: 14px;
  line-height: 1.4em;
  // letter-spacing: -.5px;

  @include sm{
    font-size: 14px;
  }
}

span{
  font-size: 14px;

  @include sm{
    font-size: 14px;
  }
}

@include md {

  h1{
    font-size: 6vw;
  }

  h2{
    font-size: 6vw;
  }

  h3{
    font-size: 3vw;
  }
}


@include sm {

  h1{
    font-size: 2em;
  }

  h2{
    font-size: 2em;
  }

  h3{
    font-size: 1.6em;
  }
}

h1, h2, h3, h4, h5, h6, p, span, a, textarea, input, button, select, nav, header, .preload-container{
    &:not(.in-img){
      -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;
  }
}
