/*
|
|
Mixins
|
|
*/

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin center-block(){
      display: block;
   margin-left: auto;
  margin-right: auto;
}

@mixin scale-with-grid(){
     max-width: $width;
        height: auto;
}

@mixin absolute(){
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left:0;
     margin: auto;
}

@mixin fixed(){
     position: fixed;
     top: 0;
     right: 0;
     bottom: 0;
     left:0;
     margin: auto;
}

@mixin hidden(){
  opacity: 0;
  visibility: hidden;
}

@mixin visible(){
  opacity: 1;
  visibility: visible;
}

@mixin background(){
  background-position:center center;
  background-size:cover;
  background-repeat:no-repeat;
}


/*
|
|
Media Queries
|
|
*/

// Small devices
@mixin sm {
   @media (max-width: #{$breakpoint-mobile}) {
       @content;
   }
}

// Medium devices
@mixin md {
    @media (max-width: #{$breakpoint-tablet}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (max-width: #{$breakpoint-laptop}) {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media (max-width: #{$breakpoint-desktop}) {
       @content;
   }
}
