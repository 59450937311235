html, body{
  overflow: hidden;
}

.is-virtual-scroll {
  overflow: hidden;
}

html{
	font-size: 16px;
	cursor: default;
	color: $base;

  &.is-device, &.is-laptop{
    overflow: initial;
    overflow-x: hidden;

    body{
      overflow: initial;
      height: auto;

      &.realisation{
        overflow: hidden;
      }
    }
  }
}

body{
  cursor: default;
  font-family: $content;

/*
|
|
Loading
|
|
*/
&.is-loading{
  cursor: progress;

  header, nav, .page-view, a{
    pointer-events: none;
  }
}

/*
|
|
Transition
|
|
*/
&.is-transition{
  cursor: progress;

  header, nav, .page-view, a{
    pointer-events: none;
  }
}

/*
|
|
Is Home
|
|
*/
&.home{

  .nav-logo{
    pointer-events: none;
  }

  .current-link{
    pointer-events: none;
  }
}

/*
|
|
Is Artist
|
|
*/
&.artist{

  footer{
    display: none;
  }
}

&.realisation{
  white-space: nowrap;

  footer{
    display: none;
  }
}

&.contact{

  footer{
    display: none;
  }
}

&.legal, &.legal-notices{

  footer{
    display: none;
  }
}

&.category{

  footer{
    display: none;
  }
}

&.is-scrolling{

  .page-view{
    pointer-events: none;
  }
}
}

