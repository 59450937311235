/*
|
|
Fonts
|
|
*/
@font-face{
  font-family: "title";
  src: url('../fonts/title.eot');
  src: url('../fonts/title.svg#title') format('svg');
  src: url('../fonts/title.otf') format('opentype');
  src: url('../fonts/title.woff') format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face{
	font-family: "content";
	src: url('../fonts/content.eot');
	src: url('../fonts/content.svg#content') format('svg');
	src: url('../fonts/content.otf') format('opentype');
	src: url('../fonts/content.woff') format("woff");
	font-weight: 500;
	font-style: normal;
}

