/*
|
|
Fullscreen container
|
|
*/

.fullscreen{
  position: relative;
  @include size(100%, 100vh);
  color: $white;
  background: $white;
  overflow: hidden;

  .content-container{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 4vw;
    z-index: $level_10;
    transform: translate3d(0, 0, 0);
  }

  @include md {
    @include size(100%, 60vh);
  }

  @include sm {
    @include size(100%, 40vh);
  }
}

/*
|
|
Videos container
|
|
*/
.video-wrapper {
  @include absolute();
  @include size(100%, 100%);
  z-index: $level_1;

  .video-container, .video-home{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}

// vimeo
.vimeo-wrapper {
  @include size(100%, 100%);

  &.full-v{
    @include absolute();
    z-index: $level_1;
    overflow: hidden;
    background: $white;
  }

  iframe {

    &.full-i{
     position: absolute;
     width: 100vw;
     height: 56.25vw;
     min-height: 100vh;
     min-width: 177.77vh;
     top: 50%;
     left: 50%;
     pointer-events: none;
     transform: translate(-50%, -50%);
   }

   &.sm-i{
     position: relative;
     width: 149.3333vh;
     height: 84vh;
     pointer-events: none;

     &.is-square{
      width: 84vh;
      height: 84vh;
    }
  }
}
}

.img-wrapper{
  @include absolute();
  @include size(100%, 100%);
  z-index: $level_1;
  overflow: hidden;

  .img-container{
    @include absolute();
    @include size(100%, 100%);
    @include background();
    pointer-events: none;
  }

  &.split-container{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .img-split{
      position: relative;
      overflow: hidden;
      @include size(50%, 100%);
      @include background();
      pointer-events: none;
    }
  }
}

.is-overlayed{

  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 86%);
    z-index: $level_2;
    opacity: 0;
    transition: opacity .4s ease;
  }

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0,0,0,1) 16%, rgba(0,0,0,0) 50%);
    z-index: $level_3;
    opacity: .16;
  }
}

body{

  // &.is-virtual-scroll{

  //   .fullscreen:not(:first-child) {

  //     .vimeo-wrapper{

  //       .video-wrapper{
  //         filter: brightness(30);
  //         // opacity: 0;
  //         transition: all .4s ease;
  //       }
  //     }

  //     .img-wrapper{

  //       .img-container, .img-split{
  //         filter: brightness(30);
  //         // opacity: 0;
  //         transition: all .4s ease;
  //       }
  //     }

  //     &.in-view{

  //       .vimeo-wrapper{

  //         .video-wrapper{
  //           filter: brightness(1);
  //           // opacity: 1;
  //         }
  //       }

  //       .img-wrapper{

  //         .img-container, .img-split{
  //           filter: brightness(1);
  //           // opacity: 1;
  //         }
  //       }
  //     }
  //   }
  // }

  &.realisation{

    .is-overlayed{

      &:before{
        opacity: .16;
      }
    }

    .vimeo-wrapper {

      &.full-v{
        background: $black;
      }
    }

    &.is-fullscreen{

      .is-overlayed{

        &:before{
         opacity: 0;
       }
     }
   }
 }

 &.is-fullscreen{

  .is-overlayed{

    &:before{
      opacity: 0;
    }

    &:after{
     opacity: 0;
   }
 }
}
}
