.legal-hero{
	position: relative;
	height: auto;
	padding: 16vw 25vw 5vw 25vw;
	background: $white;
	text-align: center;

	h3, h4{
		margin-top: 4vw;
		margin-bottom: 1.4vw;
	}

	.title-container{
		overflow: hidden;
	}

	@include lg{
		padding: 16vw 20vw 5vw 20vw;
	}

	@include md{
		padding: 16vw 10vw 5vw 10vw;
	}

	@include sm{
		padding: 20vw 0 5vw 0;
	}
}


.cookie-container{

	position: absolute;
	background: $black;
	display: flex;
	align-items: center;
	padding: 1.4vw 3vw;
	width: 100vw;
	bottom: 0;
	left: 0;
	color: $white;
	z-index: $level_400;
	overflow:hidden;
	transform: translate(0,100%);
	transition: transform 1s $easeSmooth;

	p{
		font-size: 12px;
		width: 80vw;

		a{
			display: inline;
		}
	}

	.close-cookie{
		position: absolute;
		right: 0;
		display: flex;
		cursor: pointer;
		margin-right: 3vw;
		color:$black;

		svg{
			position: absolute;
			pointer-events: none;
		}
	}

	@include sm{
		
		br{
			display: none;
		}
	}
}

body{

	&.is-loaded{

		.cookie-container{
			transform: translate(0,0);
		}
	}
}