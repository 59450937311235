.preload-container{
  @include fixed();
  @include size(100%,100%);
  z-index: $level_up;

  .preload-bg{
    @include absolute();
    @include size(100%,100%);
    background: $black;
  }

.preload-counter{
  position: absolute;
  @include size(100%,auto);
  font-family: $title;
  font-size: 3.68vw;
  line-height: .9em;
  bottom: 0;
  left: 0;
  margin-left: 4vw;
  margin-bottom: 11vw;
  overflow: hidden;
  opacity: 0;
  z-index: $level_300;

  .counter{
    color: $white;
  }

    @include xl{
    margin-bottom: 14vw;
  }

    @include lg{
    margin-bottom: 17vw;
  }

  @include md{
    font-size: 6vw;
    margin-bottom: 16vw;
  }

  @include sm{
    margin-bottom: 20vw;
    font-size: 2em;
  }
}
}

.tr-container{
  display: none;

  .tr-bg{
    @include fixed();
    @include size(100%,100%);
    background: $black;
    z-index: $level_300;
    transform: translateY(100%);
  }

  .real-bg{
    @include fixed();
    @include size(100%,100%);
    background: $black;
    z-index: $level_300;
    transform: translateX(100%);
  }
}

body{

  &.is-transition{

    a{
      pointer-events: none;
    }

    .tr-container{
      display: block;
    }
  }
}

html{

  &.is-firefox{

    .tr-container{
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 1000;
      display: block;
      visibility: hidden;
      opacity: 0;
    }

    body{

      &.is-transition{

        .tr-container{
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
