/*
|
|
Artist
|
|
*/
.artist-hero{
  height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &.is-base{
    background: $white;
  }

  .category-list{
    display: flex;

    h5{
      cursor: pointer;

      span{
        padding: 0 4px;
      }

      &:last-child{

        span{
          display: none;
        }
      }
    }
  }

  .artist-container{
    z-index: $level_10;
  }

  .submenu-container{
    position: absolute;
    width: auto;
    bottom: 0;
    font-family: $title;
    text-transform: uppercase;
    margin-bottom: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .infos-link{
      cursor: pointer;
    }

    .pdf-link{
      cursor: pointer;
      display: flex;
      margin-left: 1vw;

      span{
        margin-right: 1vw;
      }
    }
  }


  @include sm {
    height: 40vh;
  }
}

.infos-container{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin: auto;
  will-change: transform;
  display: none;
  pointer-events: none;
  z-index: $level_600;

  .close-mobile{
    position: absolute;
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: $level_100;

    svg{
      width: 13px;
      height: auto;
      fill: $white;

      path{
        fill: $white;
      }
    }
  }

  .infos-close-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $level_1;
  }

  .artist-hero{
    z-index: $level_2;

    .rs-container{
      display: flex;
      margin-top: 4vw;
      z-index: $level_2;
      overflow: hidden;

      a{
        padding: 0 1vw;

        svg{
          height: 16px;
          width: auto;
          fill: $white;

          path{
            fill: $white;
          }
        }
      }
    }
  }
}

.js-artist, .js-infos-title, .js-infos-content{
  overflow: hidden;
}

.profil-container{
  position: relative;
  height: auto;
  width: 50vw;
  margin:auto;
  z-index: $level_2;

  .credit-photo{
    position: absolute;
    z-index: 2;
    bottom: 0;
    font-size: 12px;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    margin-bottom: -6vw;
    white-space: nowrap;
    margin-left: 4vw;
    transform: rotate(-90deg);
    z-index: $level_2;
  }

  .img-container{
    position: relative;
    @include size(100%, auto);
    @include background();
    background: lightpink;
    margin-top: -16vh;
    z-index: $level_2;
  }

  .infos-big-title{
    position: absolute;
    font-family: $title;
    font-size: 20vw;
    opacity: .05;
    white-space: nowrap;
    color: $white;
    text-transform: uppercase;
    top: 50%;
    left: -50%;
    margin-top: -10vw;
    pointer-events: none;
    z-index: $level_1;
  }

  @include sm{
    width: 75vw;

    .credit-photo{
      height: auto;
      margin-left: 0vw;
      transform: rotate(0deg);
    }

    .img-container{
      margin-top: -8vh;
    }
  }
}

.infos-end{
  width: 50vw;
  margin: auto;
  margin-top: 8vh;
  padding-bottom: 20vh;
  z-index: $level_2;

  .info-in{
    width: 35vw;
    margin-left: 15vw;
  }

  a{
    position: relative;
    width: 35vw;
    margin-left: 15vw;
    margin-top: 4vw;
    z-index: $level_2;
  }

  @include sm{
    width: 75vw;

    .info-in{
      width: 100%;
      margin-left: 0vw;
    }

    a{
      width: 100%;
      margin-left: 0vw;
    }
  }

  &.no-img{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .info-in, .site-link{
      width: 100%;
      margin-left: 0vw;
    }
  }
}

.infos-bg{
  @include fixed();
  @include size(100%,100%);
  background: $black;
  z-index: $level_500;
  transform: translateY(100%);
  display: none;
}

.more-wrapper{
  position: relative;
  z-index: $level_20;
}

.load-more{
  position: absolute;
  width: 100%;
  padding-top: 8vw;
  cursor: pointer;
  overflow: hidden;
  display: none;

  &:first-child{
    display: block;
  }
}

.more-container{
  position: relative;
  height: auto;
  width: 100%;
  display: none;
  opacity: 0;
  transform: translateY(4vw);
}

.an-name{
  margin-left: 3vw;
  padding-top: 0vw;
  margin-bottom: 0vw;
  height: 0px;
  opacity: 0;

  &:first-child{
    padding-top: 0vw;
    margin-bottom: 0vw;
    height: 0px;
  }

  @include sm{
    margin-bottom: 6vw;
    height: auto;
    opacity: 1;
    padding-top: 16vw;
  }
}

.scrollto-fix{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $level_100;
  padding: 1.5vw 3vw;
  pointer-events: none;
  text-align: left;
  display: none;
  background:$white;
  transform: translateY(-100%);
  transition: transform .8s $easeCustom;

  ul{
    display: inline-block;
    overflow: hidden;
    max-width: 40vw;

    li{
      position: relative;
      display: inline-block;
      color: $black;
      cursor: pointer;
      transition: opacity .4s ease;

      &:not(:last-child) {
        margin-right: 1.4vw;

        &:before{
          content: "/";
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -1vw;
          pointer-events: none;
          color: $black;
        }
      }
    }

    &.is-hovering{

      li{

        &:not(.is-hovered){
          opacity: .12;
        }
      }
    }
  }

  @include sm{
    pointer-events: none;
    opacity: 0;

    ul{

      li{
        transform: translateY(-100%);
      }
    }
  }
}

.scrollto-container{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: $level_300;
  padding: 0vw 0vw;
  margin-bottom: 2.55vw;
  pointer-events: none;
  text-align: left;
  mix-blend-mode: difference;

  ul{
    display: inline-block;
    overflow: hidden;
    max-width: 40vw;

    li{
      position: relative;
      display: inline-block;
      color: $white;
      cursor: pointer;
      transform: translateY(-100%);
      transition: opacity .4s ease, transform .8s $easeCustom .4s;

      &:not(:last-child) {
        margin-right: 1.4vw;

        &:before{
          content: "/";
          position: absolute;
          top: 0;
          right: 0;
          margin-right: -1vw;
          pointer-events: none;
          color: $white;
        }
      }
    }

    &.is-hovering{

      li{

        &:not(.is-hovered){
          opacity: .12;
        }
      }
    }
  }

  @include sm{
    pointer-events: none;
    opacity: 0;

    ul{

      li{
        transform: translateY(-100%);
      }
    }
  }
}

.artist-next{
  height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  span{
    margin-bottom: 2vw;
    font-size: .84em;
  }

  @include sm {
    height: 40vh;
  }
}

.artist-menu{
  position: fixed;
  @include size(100vw, 100vh);
  top: 0;
  left: 0;
  z-index: $level_500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;

  .artist-menu-bg{
    @include absolute();
    @include size(100%, 100%);
    background: $white;
    z-index: $level_last;
    pointer-events: none;
  }

  .artist-category{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    z-index: $level_20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .artist-category-hidden{
      @include absolute();
      @include size(100%, 100%);
      z-index: $level_10;
      pointer-events: none;
    }

    .artist-category-container{
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: $level_20;
      pointer-events: none;

      a{
        overflow:hidden;
        transition: opacity 1s ease;
      }

      &.is-hovered{

        a{
          opacity: .24;

          &.is-hovering{
            opacity: 1;
          }
        }
      }
    }

    &.is-on{
      pointer-events: initial;
      opacity: 1;

      .artist-category-hidden, .artist-category-container{
       cursor: pointer;
       pointer-events: initial;
     }
   }
 }
}

body{

  &.artist{

    .scrollto-fix{
      display: block;
    }

    .scrollto-container{
      pointer-events: initial;

      ul{

        li{
          transform: translateY(0%);
        }
      }
    }

    &.is-menu-open{

      .scrollto-fix{
        transform: translateY(-100%);
        pointer-events: none;
      }

      .scrollto-container{
        pointer-events: none;

        ul{

          li{
            transform: translateY(-100%);
            transition: opacity .4s ease, transform .8s $easeCustom 0s;
          }
        }
      }
    }
  }

  &.is-infos{

    .infos-bg{
      display: block;
    }

    .infos-container{
      display: block;
      pointer-events: auto;
      cursor: pointer;
    }
  }

  &.is-more{

    .more-container{
      display: block;
    }
  }

  &.is-category-on{

    .scrollto-fix{
      transform: translateY(0%);
      pointer-events: initial;

      ul{

        li{
          opacity: .12;

          &.is-on{
            opacity: 1;
          }
        }

        &.is-hovering{

          li{

            &.is-hovered{
              opacity: 1;
            }

            &:not(.is-hovered){
              opacity: .12;
            }
          }
        }
      }
    }
  }

  &.is-artist-category{

    .artist-menu{
      pointer-events: initial;
      opacity: 1;
    }
  }
}

html{

  &.is-device{

    body{

      &.is-infos{
        overflow: hidden;
      }
    }

    .infos-container{
      height: 100vh;
      overflow-y: scroll;

      .close-mobile{
        display: flex;
      }

      .infos-big-title{
        display: none;
      }
    }
  }
}
