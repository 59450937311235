/*
|
|
Home
|
|
*/
.hero-section{

  .hero-content{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 2vw;
    z-index: $level_10;

    p{
      font-size: 1em;
    }

    .js-link{
      font-family: $title;
      display: inline-block;
      margin-right: 1vw;

      &.link-right{
        float: right;
      }
    }

    .home-social-ul{
      display: flex;
      align-items: center;
      overflow:hidden;
      margin-top: 2vw;

      li{
        margin-left: 1.4vw;

        &:first-child{
          margin-left: 0vw;
        }

        a{

          svg{
            height: 12px;
            width: auto;
            fill: $white;
          }
        }
      }
    }
  }

  .video-wrapper{

  &:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(0,0,0,1) 32%, rgba(0,0,0,0) 54%);
    z-index: $level_3;
    opacity: .24;
  }
  }

  @include md {
    @include size(100%, 100vh);

    .hero-content{

      .home-social-ul{

        li{
          margin-left: 2vw;
        }
      }
    }
  }

  @include sm {

    .hero-content{
     margin-bottom: 6vw;

     .home-social-ul{
      margin-top: 6vw;

      li{
        margin-left: 4vw;
      }
    }
  }
}
}

.link-artist{
  display: flex;

  span{
    margin-top: .4vw;
  }

  h2{
    margin-right: 1vw;
  }

  @include sm {
    span{
     display: none;
   }

 }
}
